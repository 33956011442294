@import '../_styles/colours';

footer {
  z-index: 600;
  opacity: 0;
  pointer-events: none;

  &[data-show="true"] {
    opacity: 1;
    pointer-events: all;
  }
}
.footer {
  &__inner {
    display: grid;
  }

  &__title {
    font-size: 1.125rem;
    margin-bottom: .1875rem;
    line-height: 1.375rem;
    letter-spacing: -0.025rem;
    font-weight: 500;
  }
  &__subtitle,
  &__rhs {
    font-size: .9375rem;
    font-weight: 500;
    line-height: calc((16/14) * 1em);
  }

  &__rhs {
    text-align: right;

    a {
      color: $white;
      text-decoration: none;
    }
  }
  &__copyright {
    &:before {
      content: '© ';
    }
    margin-bottom: .5rem;
  }
}

.App {
  &[data-layout="col-1"] {
    footer {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 3rem;
  
      .footer {
        &__inner {
          padding-top: calc(1rem - 1px);
          border-top: 1px solid $white;
          grid-template-areas: 'lhs . rhs';
          grid-template-columns: 6.25rem 1fr auto;
          align-items: start;
        }
        &__lhs {
          grid-area: lhs;
        }
        &__rhs {
          grid-area: rhs;
        }
      }
    }
  }
  &[data-layout="col-2"],
  &[data-layout="col-3"],
  &[data-layout="col-4"] {
    footer {
      text-align: right;
      transition: ease-in-out 250ms opacity;
      position: fixed;
      left: 48px;
      bottom: 64px;
  
      .footer {
        &__inner {
          grid-auto-flow: row;
          grid-gap: 2rem;
        }
      }
    }
  }
  &[data-layout="col-2"],
  &[data-layout="col-3"] {
    footer {
      width: 180px;
    }
  }
  &[data-layout="col-4"] {
    footer {
      width: 200px;
    }
  }
}