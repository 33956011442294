@import '../_styles/colours';
@import '../_styles/button';

.nav--admin {
  padding: 64px 48px;
  width: 296px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;

  .nav {
    &__mark {
      margin-bottom: 1.5rem;
      width: 100%;
      height: 80px;
    }
    &__logout {
      @include ButtonPrimary;
    }
    &__list {
      margin-top: 4.5rem;
      padding: 1.5rem 0;
      list-style: none;
    }
    &__item {
      display: flex;
      cursor: pointer;
      font-size: 1.125rem;
      margin-bottom: 1rem;
      &:last-child { margin-bottom: 0; }
  
      &:before {
        content: '>';
        display: block;
        width: 0px;
        overflow: hidden;
        transition: 100ms linear;
        flex: none;
      }
    }
    &__item:hover,
    &__current {
      &:before {
        width: 1rem;
      }
    }
  }
}