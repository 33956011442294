@import '../../_styles/colours';
@import '../../_styles/typo';

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dancel {
  0% {
    left: -4px;
  }
  50% {
    left: 4px;
  }
  100% {
    left: -4px;
  }
}
@keyframes dancer {
  0% {
    right: -4px;
  }
  50% {
    right: 4px;
  }
  100% {
    right: -4px;
  }
}

.nav__drawer,
.cta__projects {
  .project__group {
    margin-bottom: 24px;
    &:last-child { margin-bottom: 0; }
  }
  .project__group,
  .project__list {
    break-inside: avoid;
  }
  .project__list__item {
    list-style: none;

    a {
      display: grid;
      grid-template-areas: 'title gap completed';
      align-items: start;
      grid-template-columns: auto 1fr auto;
      grid-gap: .5rem;
      font-size: 1rem;
      padding: .5rem 1px;
      letter-spacing: 0.7px;
      line-height: 1.125em;
      font-weight: 500;
      cursor: pointer;
      transition: ease-in-out 150ms color;
    
      &:before {
        content: '..........................................................................................................................................................................................................................................................................................................................................';
        speak: none;
        letter-spacing: 1.4px;
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        // width: 100%;
        grid-area: gap;
        transform: translateY(-25%);
        display: block;
      }
      .project__list__item {
        &__title {
          grid-area: title;
          max-width: 11.25rem;
          display: flex;
          align-items: center;
      
          &:before {
            content: '>';
            speak: none;
            width: 0;
            overflow: hidden;
            display: inline-block;
            transition: linear 150ms width;
            flex: none;
          }
        }
        &__completed {
          grid-area: completed;
        }
      
        &--img {
          list-style: none;
          padding: .5rem 0;
          transition: 150ms ease-in-out opacity;
      
          img {
            width: 100%;
          }
      
          @media (hover:hover) {
            &:hover {
              opacity: .6;
            }
          }
        }
      }
    }
    &:hover {
      .project__list__item__title:before {
        width: 1rem;
      }
    }
  }
  @media (hover:hover) {
    .project__list__item:hover {
      color: $whitedim;
    }
  }
}
.landing__projects__lists {
  .project {
    &__group {
      user-select: none;
      margin-bottom: 0;
      // margin-bottom: 2rem;
      // &:last-child { margin-bottom: 0 }
      // break-inside: avoid;
      display: flex;
      flex-direction: column;
  
      .subsection__head {
        flex: none;
      }
    }
    &__list {
      list-style: none;
      position: relative;
      overflow: hidden;
      // break-inside: avoid;
      flex: 1;
    
      a {
        display: inline-flex;
        flex-direction: column;
        text-decoration: none;
        position: relative;
        padding: 0 24px;

        &, &:hover {
          color: $black;
        }
        &:before,
        &:after {
          position: absolute;
          top: 50%;
          transform: translate3d(0,-60%,0);
          width: 0;
          overflow: hidden;
          display: inline-block;
          flex: none;
          font-size: 1.25em;
          line-height: .8em;
          transition: 150ms ease-in-out;
        }

        &:before {
          content: '>';
          text-align: left;
          left: 0;
        }
        &:after {
          content: '<';
          text-align: right;
          right: 0;
        }
      }
      &__item {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        flex-direction: row;

        &:hover {
          a {
            .project__list__item {
              &__title,
              &__completed {
                animation: flicker 1500ms linear infinite;
              }
            }

            &:before,
            &:after {
              width: 24px;
            }
            &:before {
              animation: dancel 1000ms ease infinite;
            }
            &:after {
              animation: dancer 1000ms ease infinite;
            }
          }
        }
      }
      h1, h2 {
        text-indent: -9999px;
      }
      &__items {
        position: relative;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .sprite {
        display: block;
        position: absolute;
        background-size: contain;
        pointer-events: none;
        user-select: none;
  
        &[class*="top"] {
          top: 0;
        }
        &[class*="left"] {
          left: 0;
        }
        &[class*="right"] {
          right: 0;
        }
        &[class*="bottom"] {
          bottom: 0;
        }
      }
  
      &[data-category="marketing"] {
        padding: 12px;
        background-color: #BAB4FF;
        text-align: center;
        display: flex;
        flex-direction: column;
  
        .project__list {
          &__head {
            margin-bottom: 4%;
            display: flex;
            flex-direction: column;
            align-items: center;
  
            h1 {
              position: relative;
              display: block;
              background-image: url('/assets/projects/marketing_title.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: top center;
              // width: calc(100% - 24px);
              width: 100%;
              height: 0;
              padding-bottom: 35%;
              margin-bottom: -20%;
              z-index: 0;
  
  
              &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 45%;
                transform: translateX(-50%);
                width: 120%;
                height: 350%;
                padding-bottom: 100%;
                background-image: url('/assets/projects/marketing_bg.svg');
                background-size: contain;
                background-position: top center;
                background-repeat: no-repeat;
              }
            }
            h2 {
              // position: absolute;
              // top: 12px;
              // left: 12px;
              display: block;
              background-image: url('/assets/projects/marketing_subtitle.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 97%;
              // width: 100%;
              height: 0;
              padding-bottom: 7%;
              position: relative;
              z-index: 5;
            }
          }
          &__items {
            @include Silom;
            margin: 0 24px;
            padding: 24px 16px;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.9px;
            text-transform: uppercase;
            color: $black;
            display: flex;
            flex-direction: column;
          }
          &__item {
            margin-bottom: 16px;
            &:last-child { margin-bottom: 0; }
  
            a {
              display: flex;
              flex-direction: column;
            }
  
            &__completed {
              margin-top: 6px;
              font-size: 15px;
              line-height: 15px;
              letter-spacing: -0.6px;
  
              &:before {
                content: '(';
              }
              &:after {
                content: ')';
              }
            }
          }
  
        }
        .sprite {
          &--top {
            background-image: url('/assets/projects/marketing_sprite_top.jpg');
          }
          &--bottom {
            background-image: url('/assets/projects/marketing_sprite_bottom.jpg');
          }
          &--right {
            background-image: url('/assets/projects/marketing_sprite_right.jpg');
          }
          &--left {
            background-image: url('/assets/projects/marketing_sprite_left.jpg');
          }
          &--topleft {
            background-image: url('/assets/projects/marketing_sprite_topleft.png');
          }
          &--topright {
            background-image: url('/assets/projects/marketing_sprite_topright.png');
          }
          &--bottomleft {
            background-image: url('/assets/projects/marketing_sprite_bottomleft.png');
          }
          &--bottomright {
            background-image: url('/assets/projects/marketing_sprite_bottomright.png');
          }
          &--top,
          &--topleft,
          &--topright,
          &--bottom,
          &--bottomleft,
          &--bottomright {
            height: 12px;
          }
          &--topright,
          &--topleft,
          &--left,
          &--bottomright,
          &--bottomleft,
          &--right {
            width: 12px;
          }
          &--middle,
          &--left,
          &--right {
            top: 12px;
            bottom: 12px;
          }
          &--middle,
          &--top,
          &--bottom {
            left: 12px;
            right: 12px;
          }
          &--middle {
            background-color: white;
          }
        }
      }
      &[data-category="articles"] {
        display: flex;
        flex-direction: column;
        padding: 16px;
        padding-right: calc(50% + 8px);
        background-color: #E7E7E7;
        text-align: center;
        color: $black;
  
        .project__list {
          &__head {
            margin-bottom: 28px;
  
            h1, h2 {
              text-indent: 0;
            }
            h1 {
              @include Amador;
              font-size: 26px;
              line-height: 18px;
              letter-spacing: 0px;
              margin-bottom: 4px;
              font-weight: 400;
              display: flex;
  
              span {
                display: inline-block;
                flex: none;
                margin: 0 8px 0 10px;
              }
  
              &:before,
              &:after {
                content: '';
                flex: 1;
                border-top: $black 2px solid;
                border-bottom: $black 2px solid;
                height: 6px;
                display: inline-block;
                transform: translateY(4px);
  
              }
            }
            h2 {
              @include Prohibition;
              position: relative;
              font-size: 52px;
              letter-spacing: -1.5px;
              line-height: 52px;
              text-align: center;
  
              span {
                display: block;
                position: relative;
  
                &:first-child {
                  margin-bottom: 8px;
  
                  &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: calc(100% + 6px);
                    height: 2px;
                    background-color: $black;
                    width: calc(100% - 2px);
                    transform: translateX(-50%);
                  }
                }
              }
            }
          }
          &__items {
            font-family: adobe-caslon-pro, Times, serif;
            font-size: 18px;
            color: #030308;
            letter-spacing: -0.36px;
            text-align: center;
            line-height: 20px;
            font-weight: 700;
            font-style: normal;
          }
          &__media {
            position: absolute;
            width: calc(50% - 16px);
            top: 0;
            height: 100%;
            right: 0;
            background-image: url('/assets/projects/articles.jpg');
            background-size: cover;
            background-position: center;
          }
          &__item {
            margin-bottom: 16px;
            &:last-child { margin-bottom: 0; }
  
            &__completed {
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;
              margin-top: 6px;
              letter-spacing: 0;
  
              &:before {
                content: '(';
              }
              &:after {
                content: ')';
              }
            }
          }
        }
      }
      &[data-category="storytelling"] {
        background-image: url('/assets/projects/storytelling_bg.jpg');
        background-size: cover;
        background-position: center;
        padding: 10px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        --sprite: 14px;
  
        .project__list {
          &__head {
            width: 100%;
            height: 0;
            padding-bottom: 90%;
            background-image: url('/assets/projects/storytelling_head.jpg');
            background-size: cover;
            background-position: top center;
            // margin-bottom: calc(var(--sprite) * -0.5);
          }
          &__items {
            @include Luminari;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 68px 24px 48px;
            // font-family: 'Luminari-Regular',Times, serif;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            line-height: 18px;
            flex: 1;
  
            h2 {
              span {
                position: absolute;
                z-index: 10;
                pointer-events: none;
                user-select: none;
  
                &:first-child {
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 93px;
                  background-image: url('/assets/projects/storytelling_existing.png');
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: top center;
                  transform: translateY(-37px);
                }
                &:last-child {
                  top: 100%;
                  left: 0;
                  width: 100%;
                  height: 57px;
                  background-image: url('/assets/projects/storytelling_notquite.png');
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: top center;
                  transform: translateY(-36px);
                }
              }
            }
  
            .sprite {
              &--top,
              &--topleft,
              &--topright,
              &--bottom,
              &--bottomleft,
              &--bottomright {
                height: var(--sprite);
              }
              &--left,
              &--right,
              &--topright,
              &--topleft,
              &--bottomright,
              &--bottomleft {
                width: var(--sprite);
              }
              &--topright,
              &--topleft,
              &--bottomright,
              &--bottomleft {
                background-image: url('/assets/projects/storytelling_sprite_corner.svg');
                background-size: contain;
                z-index: 5;
              }
              &--top,
              &--bottom,
              &--right,
              &--left {
                z-index: 0;
              }
              &--top,
              &--bottom {
                left: calc(var(--sprite) / 2);
                right: calc(var(--sprite) / 2);
                background-image: url('/assets/projects/storytelling_sprite_topbottom.svg');
                background-size: auto 100%;
              }
              &--right,
              &--left {
                top: calc(var(--sprite) / 2);
                bottom: calc(var(--sprite) / 2);
                background-image: url('/assets/projects/storytelling_sprite_leftright.svg');
                background-size: contain;
              }
            }
          }
          &__item {
            color: $black;
            width: 100%;
            margin-bottom: 16px;
            &:last-child { margin-bottom: 0; }
            
            a {
              display: inline-flex;
              flex-wrap: wrap;
              justify-content: center;
              flex-direction: row;
            }
            &__title,
            &__completed {
              display: inline;
            }
            &__completed {
              margin-left: 8px;
  
              &:before {
                content: '(';
              }
              &:after {
                content: ')';
              }
            }
          }
        }
      }
    }
  }
}

.App {
  &[data-layout="col-1"] {
    .project__list {
      margin-bottom: 1.5rem;
    }
    .landing__projects__lists {
      .project__list {
        &[data-category="articles"] {
          --thumb: 180px;
          padding-right: 16px;
          padding-top: calc(var(--thumb) + 16px);

          .project__list {
            &__media {
              width: 100%;
              top: 0;
              height: var(--thumb);
              right: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}