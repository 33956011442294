@import '../../_styles/colours';
@import '../../_styles/typo';

.study {
  width: 100%;

  &__embed {
    &__thumb {
      width: 100%;
      height: 27.5rem;
      margin-bottom: 2.5rem;
      position: relative;
      overflow: hidden;
  
      .playbutton {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        opacity: 0;
        pointer-events: none;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;

        &, svg {
          transition: 150ms ease-in-out;
        }
        svg {
          fill: $white;
        }
        &[data-show="true"] {
          opacity: 1;
        }
      }
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        opacity: .4;
        transition: ease-in-out 200ms opacity;
        z-index: 0;
      }

      &[data-ready="true"] {
        &[data-type="video"] {
          cursor: pointer;
        }
      }
      &[data-type="thumb"] {
        img {
          opacity: 1;
        }
      }
  
      @media (hover: hover) {
        &[data-ready="true"][data-type="video"]:hover {
          .playbutton[data-show="true"] {
            background-color: $white;

            svg {
              fill: $black;
            }
          }
          img {
            opacity: .8;
          }
        }
        &[data-type="audio"] {
          cursor: default;
  
          img {
            opacity: 1;
          }
        }
      }
    }
    &__audio {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4rem;
      background-color: $white;
      opacity: 0;
      pointer-events: none;
      z-index: 5;

      &__controls {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto 10rem 1fr;
        grid-gap: 1.25rem;
        padding: 0 1.25rem;
        align-items: center;
        color: $black;

        .controls {
          &__toggle {
            width: 2rem;
            height: 2rem;
            cursor: pointer;

            .playbutton,
            .pausebutton {
              position: static;
              transform: none;
              width: 2rem;
              height: 2rem;

              svg {
                fill: $black;
                width: 100%;
                height: 100%;
              }
            }
          }
          &__details {
            display: flex;
            flex-direction: column;
            @include NeueHaasText;
            user-select: none;

            &--title {
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &--time {
              font-size: .875rem;
              opacity: .5;
            }
          }
        }
        .input-range {
          &__label {
            display: none;
          }
          &__track {
            position: relative;
            width: 100%;
            height: 1rem;
            cursor: pointer;

            &:before {
              content: '';
              background-color: $black;
              opacity: .4;
              width: 100%;
              display: block;
              position: absolute;
              left: 0;
              height: .25rem;
              top: 50%;
              transform: translateY(-50%);
            }
            &--active {
              position: absolute;
              background-color: $black;
              height: .25rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &__slider {
            width: 100%;
            height: 100%;

            &-container {
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 50%;
              background-color: $black;
              top: 50%;
              transform: translate(-50%,-50%);
              cursor: pointer;
            }
          }
        }
      }

      &[data-ready="true"] {
        opacity: 1;
        pointer-events: all;
      }
    }
    &__modal {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 2000;
      opacity: 0;
      pointer-events: none;
      padding: 0 5rem;
      transition: 200ms ease-in-out opacity;
      display: flex;
      align-items: center;
      overflow: hidden;
      overscroll-behavior: contain;

      &__inner {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      
      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: .9;
      }

      &__close {
        position: absolute;
        padding: 0;
        border: 0;
        outline: 0;
        left: 5rem;
        top: 64px;
        transform: translateY(-50%);
        background-color: transparent;
        font-size: 1.5rem;
        transition: 150ms ease-in-out;
        color: $white;
        cursor: pointer;

        @media (hover: hover) {
          &:hover {
            opacity: .5;
          }
        }
      }

      &[data-show="true"] {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &__head {
    margin-bottom: 3rem;
  }
  &__pretitle {
    text-transform: uppercase;
    font-weight: 700;
    font-size: .875rem;
    letter-spacing: .0125rem;
    line-height: 1.0625rem;
    margin-bottom: 3.875rem;
  }
  &__title {
    display: flex;
    width: 100%;
    margin-bottom: 2.75rem;

    h1 {
      font-size: 3.5rem;
      line-height: 3.375rem;
      letter-spacing: -0.0625rem;
      font-weight: 600;
      padding-bottom: .875rem;
      border-bottom: 1px solid $white;
      flex: 1;
      margin: 0;
      transition: opacity 200ms ease-in-out;
    }
  }
  h1,
  &__meta,
  &__extlink__label {
    user-select: none;
  }
  &__extlink {
    flex: none;
    margin-left: 2rem;
    position: relative;
    cursor: pointer;
    width: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $white;
      content: '';
      z-index: 0;
      border: 1px solid $white;
      box-sizing: border-box;
    }

    &__content {
      color: $black;
      position: absolute;
      z-index: 1;
      height: 1.75rem;
      right: 1rem;
      top: 50%;
      transform: translate(0,-50%);
      display: flex;
      flex: none;
      width: 8rem;
      justify-content: flex-end;
      pointer-events: none;

      svg {
        flex: none;
        width: 1.75rem;
        height: 1.75rem;
        display: block;
      }
    }
    &__label {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5625rem;
      letter-spacing: .025rem;
      padding-top: .125rem;
      opacity: 0;
      margin-right: .25rem;
      flex: none;
    }
    &__icon {
      fill: $black;
    }
    &:before,
    &__content,
    &__label,
    &__icon {
      transition: 200ms ease-in-out;
    }

    @media (hover: hover) {
      &:hover {
        &:before {
          background-color: $black;
  
        }
        .study__extlink__label {
          color: $white;
  
        }
        .study__extlink__icon {
          fill: $white;
        }
      }
    }
  }
  @media (hover: hover) {
    &__title[data-haslink="true"] {
      &:hover {
        h1 {
          opacity: .2;
        }
        .study__extlink {
          &:before {
            width: 14rem;
          }
          &__label {
            opacity: 1;
          }
          &__content {
            right: 3rem;
            justify-content: center;
            pointer-events: all;
          }
        }
      }
    }
  }
  &__meta {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2.25rem;
    align-items: start;
    width: 100%;

    &__field {
      padding-bottom: .4375rem;
      border-bottom: 1px solid $white;
      
      &__label {
        @include NeueHaasText;
        margin-bottom: .4375rem;
        font-size: .625rem;
        font-weight: 400;
        letter-spacing: .2rem;
        line-height: .8125rem;
        text-transform: uppercase;
      }
      &__value {
        font-size: 1.375rem;
        letter-spacing: .0375rem;
        line-height: 1.4375rem;
        font-weight: 500;
      }
    }
  }

  &__content {
    margin-bottom: 5rem;

    p {
      @include NeueHaasText;
      font-size: 1rem;
      line-height: calc((26/16) * 1em);
    }
    p,
    .media {
      margin-bottom: 1.75rem;
      &:last-child { margin-bottom: 0; }
    }
  }

  &__cta {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
    align-items: start;
  }
}

.App {
  &[data-layout="col-1"] {
    .study {
      padding-top: 6rem;
      &__head {
        margin-bottom: 2rem;
      }
      &__embed {
        &__thumb {
          height: 15rem;
        }
        &__modal {
          padding: 0 20px;

          &__close {
            left: 20px;
          }
        }
      }
      &__pretitle {
        margin-bottom: 3rem;
      }
      &__title {
        flex-direction: column;
        margin-bottom: 2rem;

        h1 {
          font-size: 1.875rem;
          line-height: 2rem;
          letter-spacing: .00625rem;
        }
        &[data-haslink="true"] {
          h1 {
            border-bottom: 0;
          }
        }
        &:hover {
          h1 {
            opacity: 1;
          }
        }
      }
      &__extlink {
        width: 100%;
        background-color: $white;
        border: 1px solid $white;
        margin: 0;
        height: 2.5rem;

        &:before {
          content: none;
        }
        &__content {
          position: static;
          width: unset;
          transform: none;
        }
        &__label {
          opacity: 1;
        }

        @media (hover:hover) {
          &:hover {
            background-color: $black;
          }
        }
      }
      &__meta {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__field {
          padding-bottom: .25rem;
          width: 100%;
          margin-bottom: 1rem;
          &:last-child { margin-bottom: 0; }

          &__value {
            font-size: 1.1875rem;
            line-height: 1.3125rem;
          }
        }
      }
    }
  }
  &[data-layout="col-1"],
  &[data-layout="col-2"] {
    .study {
      &__cta {
        grid-template-columns: 1fr;
      }
    }
  }
  &[data-layout="col-3"] {
    .study {
      &__cta {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}