@mixin reset-def {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin reset-button {
  @include mixin-def;
  border: 0;
  background-color: transparent;
}

* {
  @include reset-def;
}