@import '../_styles/colours';
@import '../_styles/typo';

.project__editor__draftjs {
  padding-left: 4rem;
  position: relative;
}

.DraftEditor {
  &-root {
    padding: 1rem;
    position: relative;
    border: 2px solid $white;
    border-radius: 1rem;

    &:before {
      content: 'Project Description';
      position: absolute;
      top: -0.75rem;
      left: .5rem;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      padding: 0 .5rem;
      background-color: $black;
      color: $white;
      pointer-events: none;
      user-select: none;
    }
  }
  &-editorContainer {
    .block {
      position: relative;
      margin-bottom: 1rem;
      &:last-child { margin-bottom: 0; }
      padding: 0;
    }
    .p {
      line-height: 1.6em;
    }
    img {
      object-fit: contain;
      object-position: center;
      max-width: 100%;
    }
  }

  figure {
    figcaption {
      border: 1px solid $white;
      padding: 8px;
      text-align: center;
    }
  }
}

.md {
  &-block {
    margin-bottom: 1.5rem;
    &:last-child { margin-bottom: 0; }
  }
  &-side-toolbar {
    left: 0;
    position: absolute;
  }
  &-editor-toolbar {
    display: none;
  }
  &-add-button {
    width: 40px;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: $white;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      width: 28.4px;
      height: 28.4px;
      position: absolute;
      background-color: $white;
      transform-origin: center;
      right: 0;
      top: 50%;
      transform: translate3d(54%,-50%,0) rotateZ(45deg);
    }
    &, svg {
      transition: 150ms ease-in-out;
    }
    svg {
      position: relative;
      fill: $black;
      z-index: 2;
    }

    &.md-open-button {
      svg {
        transform: rotateZ(45deg);
      }
    }
  }
  &-block-image {
    figcaption {
      border: 1px solid $white;
      padding: 8px;
      text-align: center;

      &.md-block-image-caption--empty {
        div>span:before {
          content: 'Add image caption...';
          font-style: italic;
          font-size: .875rem;
          opacity: .75;
        }
      }
    }
  }
}

.addimage {
  width: 40px;
  height: 40px;
  padding: 8px 4px 4px 6px;
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  border: 2px solid $white;
  border-top-width: 0;

  &,
  svg {
    transition: 150ms ease-in-out;
  }


  svg {
    width: 100%;
    height: 100%;
    fill: $white;
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    background-color: $white;

    svg {
      fill: $black;
    }
  }
}