@import './_styles/index';

html {
  font-size: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  // scroll-behavior: smooth;
}