@import '../_styles/colours';

@mixin fieldLabel {
  font-size: .875rem;
  letter-spacing: 0.6px;
}
@mixin secondaryField {
  margin: 1rem 0 1.5rem;
  padding: 1rem 1rem .875rem;
  display: flex;
  flex-direction: column;
  border: 2px solid $white;
  border-radius: .75rem;

  &>.field__label {
    position: absolute;
    top: -.8em;
    background-color: $black;
    padding: 0 .5em;
    left: .5rem;
    @include fieldLabel;
    margin-bottom: 1.25rem;
  }
}
@mixin checkInputContainer {
  padding: .1rem 0;
  display: flex;
  align-items: center;
  margin-bottom: .55rem;
  &:last-child { margin-bottom: 0; }
  cursor: pointer;

  span {
    transition: linear 100ms opacity;
    user-select: none;
  }

  &:hover {
    span,
    svg {
      opacity: .6;
    }
  }
}
@mixin checkInput {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: .75rem;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: $white;
    transition: linear 100ms opacity;
  }
}
@mixin selectContainer {
  position: relative;

    svg {
      fill: $white;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 0;
      pointer-events: none;
      top: 50%;
      transform: translate3d(0,-50%,0);
    }
    &__value {
      transition: 100ms linear opacity;
      padding-right: 1.5rem;
    }
    select {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    &:hover {
      svg,
      .input__select__value {
        opacity: .6;
      }
    }
}

@keyframes upload {
  0%, 100% {
    opacity: .1;
  }
  50% {
    opacity: .15;
  }
}

.project__field {
  position: relative;

  &[data-field="title"] {
    padding: .5rem;
    border: 2px solid $white;
    border-radius: 1rem;
    margin-bottom: 3rem;

    label,
    input {
      font-size: 2.5rem;
      color: $white;
      letter-spacing: -0.1px;
    }
    label {
      transition: linear 150ms;
      position: absolute;
      top: 0;
      left: .5rem;
      pointer-events: none;
      user-select: none;
      padding: 0 .5rem;
      background-color: $black;
      transform: translate3d(0,.5rem,0);
    }
    input {
      font-size: 2.5rem;
      background-color: transparent;
      color: $white;
      border: 0;
      outline: 0;
      padding: 0 .5rem;
      width: 100%;
    }

    &[data-focus="true"],
    &[data-hasval="true"] {
      label {
        transform: translate3d(0,-.75rem,0);
        @include fieldLabel;
      }
    }
  }
  &[data-field="categories"] {
    @include secondaryField;
  }
  .input__check {
    @include checkInputContainer;

    .checkbox {
      @include checkInput;
    }
  }
  &[data-field="completed"] {
    @include secondaryField;
  }
  &[data-field="preview"] {
    label {
      font-size: .8125rem;
      margin-bottom: .375rem;
      display: block;
    }

    .project__field {
      &__uploader {
        position: relative;

        img,
        &__input,
        &__clear,
        &__loader {
          transition: 150ms ease-in-out;
        }

        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 0;
        }

        &__input,
        &__clear {
          position: relative;
          border: 2px solid $black;
          border-radius: 1rem;
          font-size: 1rem;
          padding: .25em .75em;
          background-color: transparent;
          color: $black;
          cursor: pointer;
          z-index: 5;
          opacity: 0;
          pointer-events: none;
          cursor: pointer;
          margin-bottom: .5rem;
          &:last-child { margin-bottom: 0; }
          text-align: center;

          &:hover {
            background-color: $black;
            color: $white;
          }
        }

        &__input {
          input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            appearance: none;
            cursor: pointer;
          }
        }

        &__loader {
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          pointer-events: 0;
          transform: translate(-50%,-50%);
          width: calc(100% - 2rem);
          text-align: center;
        }

        &:hover,
        &[data-hasimg="false"] {
          img {
            opacity: .2;
          }
          .project__field__uploader {
            &__input,
            &__submit,
            &__clear {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
        &[data-uploading="true"] {
          pointer-events: none;

          img {
            animation: upload 1500ms infinite;
          }
          .project__field__uploader {
            &__input,
            &__clear {
              opacity: 0;
            }
            &__loader {
              opacity: 1;
            }
          }
        }
        &[data-hasimg="true"] {
          display: flex;
          flex-direction: row;

          .project__field__uploader {
            &__input,
            &__clear {
              opacity: 1;
            }
            &__input {
              flex: 1;
              border-color: $black;
              color: $black;
              margin: 0;
              margin-right: 8px;

              &:hover {
                color: $white;
              }
            }
            &__clear {
              flex: none;
              background-color: red;
              color: $white;
              border-color: red;

              &:hover {
                background-color: transparent;
                color: red;
              }
            }
          }
        }
      }
    }
  }
  .input__select {
    @include selectContainer;
  }
}

.project__fieldgroup {
  @include secondaryField;
  position: relative;

  .media__preview {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    font-size: .8125rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .75rem;
    &:last-child { margin-bottom: 0; }
    background-color: rgba(0,0,0,.15);

    img,
    iframe,
    div,
    span {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    img {
      object-fit: cover;
      object-position: center;
    }
    span {
      opacity: .5;
      display: block;
      padding: 0 1rem;
      text-align: center;
    }
  }
  &__field {
    margin-bottom: .75rem;
    &:last-child { margin-bottom: 0; }
    position: relative;
    --toppad: 1.125rem;

    &[data-type="text"] {
      padding-top: 0;
      transition: linear 150ms padding;
  
      &:after,
      label {
        transition: 150ms linear;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -.25rem;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $white;
        transform-origin: left;
        transform: translate3d(0,0,0) scaleX(0);
      }
      label {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(0,0,0);
        padding: 0;
        background-color: transparent;
        pointer-events: none;
      }
      label,
      input {
        font-size: 1rem;
        letter-spacing: 0.2px;
      }
      input {
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: 0;
        color: $white;
        width: 100%;
      }

      &:hover,
      &[data-focus="true"] {
        &:after {
          transform: translate3d(0,0,0) scaleX(1);
        }
      }
      &[data-focus="true"],
      &[data-hasval="true"] {
        padding-top: var(--toppad);

        label {
          font-size: .8125rem;
          letter-spacing: .8px;
        }
      }
    }
    &[data-type="select"] {
      padding-top: var(--toppad);

      label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: .8125rem;
      }
    }
  }
  &__btns {
    display: flex;

    button {
      height: 1.5rem;
      display: flex;
      align-items: center;
      outline: 0;
      cursor: pointer;
      transition: 150ms linear;
    }
  }
  .input__select {
    @include selectContainer;
  }
}

.project__fieldmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 5000;


  button {
    &:disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
  .modal {
    &__bg,
    &__content {
      position: fixed;
      top: 0;
      right: 0;
      transition: ease-in-out 200ms;
      height:  100vh;
    }
    &__bg {
      width: 100vw;
      transition-property: opacity;
      background-color: rgba(0,0,0,.8);
      opacity: 0;
    }
    &__content {
      width: 400px;
      transition-property: transform;
      background-color: $white;
      padding: 1.5rem;
      z-index: 1;
      transform: translate3d(100%,0,0);
    }
    &__update,
    &__cancel {
      border: 0;
      font-size: .875rem;
      padding: .5rem 0;
      text-align: center;
      width: 100%;
      margin-bottom: .5rem;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 3px;
      transition: 100ms linear;
      outline: 0;
    }

    &__update {
      background-color: $black;
      color: $white;
      border: 1px solid $black;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
    &__cancel {
      border: 1px solid red;
      color: $white;
      background-color: red;

      &:hover {
        background-color: $white;
        color: red;
      }
    }
  }

  .project__fieldgroup {
    border-color: $black;

    .field__label {
      background-color: $white;
    }
    &,
    label,
    input {
      color: $black;
    }

    &__field {
      &:after {
        background-color: $black;
      }
    }
  }

  &[data-show="true"] {
    .modal {
      &__bg {
        opacity: 1;
      }
      &__content {
        transform: translate3d(0,0,0);
      }
    }
    pointer-events: all;
  }
}

.embed {
  &__toggle {
    background-color: $white;
    color: $black;
    padding: 0 .25rem 0 .375rem;
    font-size: .8125rem;
    line-height: 1.25rem;
    margin-right: .375rem;
    border: 2px solid $white;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: .25rem;
    font-weight: 400;
    flex: 1;
    justify-content: center;

    &:hover {
      background-color: transparent;
      color: $white;
    }
  }
  &__clear {
    border-radius: 50%;
    width: 1.5rem;
    line-height: 1rem;
    padding-bottom: .125rem;
    padding-right: .0625rem;
    justify-content: center;
    transform: translate3d(0,0,0) rotateZ(45deg);
    color: red;
    background-color: transparent;
    font-size: 2rem;
    flex: none;
    border: 0;

    &:hover {
      background-color: red;
      color: $white;
    }
  }
}