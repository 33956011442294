@import '../_styles/colours';
@import '../_styles/typo';
@import '../_styles/button';

@import '../_styles/anim';

.page--login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form--login {
  width: 20rem;
  
  &__title {
    text-align: center;
    width: 100%;
    margin-bottom: 3rem;
    user-select: none;
  }

  &__inputs {
    display: flex;
    flex-direction: column;

    .input__container {
      margin-bottom: 1.5rem;
      &:last-child { margin-bottom: 0; }
    }
  }
  &__submit {
    @include anim__glow;
    @include ButtonPrimary;
    margin-top: 3rem;

    &:disabled {
      animation: glow 1000ms infinite;
      pointer-events: none;
    }
  }

  &__errors {
    padding: .7rem .8rem .7rem 2.875em;
    background-color: red;
    color: $white;
    border-radius: 1.625rem;
    position: relative;
    user-select: none;
    margin-bottom: 1.5rem;

    &:before {
      content: '!';
      font-size: 1.2rem;
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: .625rem;
      width: 1.625rem;
      height: 1.625rem;
      line-height: 1.625rem;
      text-align: center;
      transform: translate(0,-50%);
      color: $white;
      background-color: $black;
      border-radius: 50%;
      overflow: hidden;
    }

    .error {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: .8125rem;
      margin-bottom: .5rem;
      font-weight: 500;
      &:last-child { margin-bottom: 0; }
    }
  }
}

