@import '../_styles/colours';

.admin {
  .input__container {
    position: relative;
    width: 100%;
    margin-top: 1.5rem;
  
    label,
    input {
      font-size: 1.25rem;
      line-height: 1em;
      letter-spacing: -0.2px;
      font-weight: 400;
      padding: .1em .3em .15em;
      width: 100%;
      color: $white;
    }
  
    label {
      position: absolute;
      left: 0;
      top: 3px;
      pointer-events: none;
      transition: 100ms linear;
    }
    input,
    textarea {
      border: 0;
      outline: 0;
      background-color: transparent;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -.5rem;
      height: 1px;
      width: 100%;
      background-color: $white;
      transform-origin: left center;
      transform: scaleX(0);
      transition: 100ms linear;
    }
    &[data-focus="true"],
    &[data-hasval="true"],
    &[data-hasval="false"]:hover {
      label {
        font-size: .875rem;
        letter-spacing: .4px;
        transform: translate3d(0,-1.5rem,0);
      }
      &:after {
        transform: scaleX(1);
      }
    }
  }
}