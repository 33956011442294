.page--loadingsession,
.page--loadingdashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page--loadingsession {
  width: 100vw;
  height: 100vh;
}
.page--loadingdashboard {
  width: 100%;
  height: 50%;
}