@import '../_styles/colours';

.section__head {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  color: $black;
  background-color: $white;
  padding: .75rem .75rem .25rem;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.App {
  &[data-layout="col-1"] {
    .section__head {
      margin-bottom: 1.5rem;
    }
  }
  &[data-layout="col-2"],
  &[data-layout="col-3"],
  &[data-layout="col-4"] {
    .section__head {
      margin-bottom: 2rem;
    }
  }
}