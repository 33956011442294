.App {
	width: 100%;
	height: 100%;
	display: flex;
	max-width: 1500px;
	margin: 0 auto;
	max-width: 100vw;
	min-height: 100vh;
	overflow: hidden;

	main {
		width: 100%;
		position: relative;

		& .loading {
			width: 100%;
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			left: 0;
			top: 0;
			font-weight: 600;
			letter-spacing: 4px;
		}
	}

	&[data-layout='col-1'] {
		flex-direction: column;
		padding: 0;

		main {
			padding: 0px 1.25rem 2rem;

			section {
				margin-bottom: 3rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&[data-template='landing'] {
				padding-top: 80px;
			}
		}
	}
	&[data-layout='col-2'],
	&[data-layout='col-3'],
	&[data-layout='col-4'] {
		display: grid;
		padding: 64px 48px;
		grid-template-columns: auto 1fr;
		align-items: start;

		main {
			section {
				margin-bottom: 5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&[data-layout="col-2"],
	&[data-layout="col-3"] {
		padding-left: 276px;
	}
	&[data-layout="col-4"] {
		padding-left: 296px;

		main {
			max-width: calc(100vw - 296px - 96px);
		}
	}
}


@keyframes fade-out {
  0% {
		opacity: 1;
		transform: translate3d(0%,0,0);
  }
  40% {
		opacity: 0;
		transform: translate3d(100%,0,0);
  }
  100% {
		opacity: 0;
		transform: translate3d(100%,0,0);
  }
}
@keyframes fade-in {
  0% {
		opacity: 0;
		transform: translate3d(100%,0,0);
  }
  60% {
		opacity: 0;
		transform: translate3d(100%,0,0);
  }
  100% {
		opacity: 1;
		transform: translate3d(0%,0,0);
  }
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  animation: fade-in 1500ms;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
}
.fade-exit.fade-exit-active {
  animation: fade-out 1500ms;
	z-index: 0;
}

.transition__container {
  z-index: 1;
}

.App {
	&[data-layout="col-1"] {
		.fade-appear-active,
		.fade-enter.fade-enter-active {
			width: calc(100% - 40px);
			left: 20px;
		}
	}
}