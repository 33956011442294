@import './_reset';
@import './_colours';
@import './_typo';
@import './_debug';

html,
body {
  background-color: $black;
  color: $white;
  font-family: sans-serif;
  @include NeueHaasDisplay;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}