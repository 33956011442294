@import '../_styles/colours';

@mixin message {
  width: 100%;
  padding: .75rem;
  border-radius: .75rem;
  background-color: $white;
  display: flex;
  color: $black;
  padding-right: 3rem;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 3rem;

  &:before {
    content: 'Message';
    font-size: .8125rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: .25rem;
    opacity: .5;
    font-weight: 400;
  }

  &__hide {
    border: 0;
    outline: 0;
    background-color: transparent;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    svg {
      width: 1rem;
      height: 1rem;
      fill: $black;
      transition: linear 100ms opacity;
    }

    &:hover {
      svg {
        opacity: .6;
      }
    }
  }
}

.page--dashboard {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;

  main {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 0px 48px 64px 24px;
  }
  section {
    margin-top: 120px;
  }
}

.dashboard__message {
  @include message;
}