.App {
  &[data-template="landing"] {
    &[data-layout="col-1"] {
      main {
        section {
          margin-bottom: 2.5rem;
        }
      }
    }
    &[data-layout="col-2"],
    &[data-layout="col-3"],
    &[data-layout="col-4"] {
      main {
        section {
          margin-bottom: 4rem;
          &:last-child { margin-bottom: 0; }
        }
      }
    }
  }
}