.App {
  .landing__projects__lists {
    display: grid;
    grid-gap: 2rem;

    .project__group {
      &[data-category="articles"] {
        grid-area: articles;
      }
      &[data-category="storytelling"] {
        grid-area: storytelling;
      }
      &[data-category="marketing"] {
        grid-area: marketing;
      }
    }
  }
  &[data-layout="col-4"] {
    .landing__projects {
      &__lists {
        // column-count: 2;
        // column-gap: 2rem;
        // column-fill: balance;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'articles storytelling'
        'marketing storytelling';
        grid-template-rows: auto auto;
      }
    }
  }
  &[data-layout="col-3"],
  &[data-layout="col-2"],
  &[data-layout="col-1"] {
    .landing__projects {
      &__lists {
        grid-template-columns: 1fr;
        grid-template-areas: 'articles'
        'marketing'
        'storytelling';
        grid-template-rows: auto auto auto;
      }
    }
  }
}