@import './colours';

@mixin ButtonPrimary {
  outline: 0;
  font-size: .9rem;
  background-color: $white;
  color: $black;
  text-align: center;
  padding: .8em 0;
  width: 100%;
  border: 1px solid $white;
  transition: 100ms linear;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 3px;

  &:hover,
  &:focus,
  &:active {
    background-color: $black;
    color: $white;
  }
}