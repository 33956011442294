@import '../_styles/colours';
@import '../_styles/button';

@mixin fieldLabel {
  font-size: .875rem;
  letter-spacing: 0.6px;
}

@mixin aboutField {
  margin: 1rem 0 1.5rem;
  padding: 1rem 1rem .875rem;
  display: flex;
  flex-direction: column;
  border: 2px solid $white;
  border-radius: .75rem;
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 3.5rem;
  &:last-child {
    margin-bottom: 0;
  }

  label,
  &__save {
    position: absolute;
  }
  label {
    font-size: 1.25rem;
    top: 0;
    transform: translateX(-.5em) translateY(-.75em);
    background-color: $black;
    padding: 0 .5em;
  }
  .input__container {
    &:after {
      content: none;
    }
  }
  textarea {
    width: 100%;
    height: 16rem;
    padding-right: 1rem;
    resize: none;
  }

  &__save {
    @include ButtonPrimary;
    padding: .375em .75em .375em 1em;
    width: unset;
    bottom: 0;
    transform: translateY(50%);
    border-radius: 1.5rem;
    border-width: 2px;

    &:disabled {
      pointer-events: none;
    }
  }

  .dashboard__about__error {
    @include message;
    background-color: red;

    &:before {
      content: 'Error';
    }
  }
  .dashboard__about__success {
    @include message;
  }

  &[data-disabled="true"] {
    &, textarea {
      pointer-events: none;
      user-select: none;
    }
  }
}
@mixin message {
  width: 100%;
  padding: .75rem;
  border-radius: .75rem;
  background-color: $white;
  display: flex;
  color: $black;
  padding-right: 3rem;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 1.25rem;

  &:before {
    content: 'Message';
    font-size: .8125rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: .25rem;
    opacity: .5;
    font-weight: 400;
  }

  &__hide {
    border: 0;
    outline: 0;
    background-color: transparent;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    svg {
      width: 1rem;
      height: 1rem;
      fill: $black;
      transition: linear 100ms opacity;
    }

    &:hover {
      svg {
        opacity: .6;
      }
    }
  }
}

@mixin award {
  display: flex;
  position: relative;
  padding: .5rem 2rem .5rem 1rem;
  width: 100%;
  margin-bottom: .75rem;
  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '-';
    position: absolute;
    left: 0;
    top: .5rem;
    font-size: 1.25rem;
    line-height: 1.3125rem;
  }
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 3rem);
    height: 2px;
    bottom: 0;
    transform: translate3d(0,0,0) scaleX(0);
    transform-origin: left;
    background-color: $white;
    transition: linear 100ms transform;
  }

  input {
    width: 100%;
    outline: 0;
    border: 0;
  }

  &__remove {
    position: absolute;
    right: 0;
    top: .5rem;
    background-color: transparent;
    display: flex;
    border: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    
    &, svg {
      flex: none;
      transition: linear 100ms;
    }
    svg {
      fill: red;
      width: 1.125rem;
      height: 1.125rem;
    }

    &:hover {
      background-color: red;

      svg {
        fill: $white;
      }
    }
  }

  &:hover,
  &[data-focus="true"] {
    &:after {
      transform: translate3d(0,0,0) scaleX(1);
    }
  }
}
@mixin testimonial {
  display: grid;
  position: relative;
  padding: 1.5rem;
  padding-right: 2rem;
  grid-template-areas: 'quote name'
  'quote title'
  'quote .';
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem 2.5rem;
  align-items: start;
  grid-template-rows: auto auto 1fr;
  width: 100%;
  margin-bottom: .75rem;
  border: 2px solid $white;
  border-radius: .5rem;
  &:last-child {
    margin-bottom: 0;
  }

  &__field {
    position: relative;
    padding-top: 1rem;

    label,
    &:after {
      transition: 100ms linear;
    }
    label {
      position: absolute;
      top: -.25rem;
      left: 0;
      font-size: .8125rem;
      letter-spacing: .2px;
      padding: 0;
      background-color: transparent;
      transform: none;
      pointer-events: none;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -.25rem;
      height: 1px;
      width: 100%;
      background-color: $white;
      transform: translate3d(0,0,0) scaleX(0);
      transform-origin: left;
    }

    &:hover,
    &[data-focus="true"] {
      &:after {
        transform: translate3d(0,0,0) scaleX(1);
      }
    }
    &[data-hasval="false"] {
      label {
        top: 1rem;
        font-size: 1rem;
        letter-spacing: 0;
      }
    }
  }
  &__quote {
    grid-area: quote;
  }
  &__name {
    grid-area: name;
  }
  &__title {
    grid-area: title;
  }

  input,
  textarea {
    width: 100%;
    outline: 0;
    border: 0;
  }

  &__remove {
    position: absolute;
    right: .5rem;
    top: .5rem;
    background-color: transparent;
    display: flex;
    border: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    
    &, svg {
      flex: none;
      transition: linear 100ms;
    }
    svg {
      fill: red;
      width: 1.125rem;
      height: 1.125rem;
    }

    &:hover {
      background-color: red;

      svg {
        fill: $white;
      }
    }
  }
}

.admin {
  .dashboard__about {
    margin-top: 120px;
  
    textarea,
    input {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .025rem;
      line-height: 1.4375rem;
      color: $white;
      background-color: transparent;
      font-family: inherit;
      padding: 0;
      border: 0;
    }
  }
  
  .about {
    &__bio,
    &__awards,
    &__testimonials {
      @include aboutField;
    }
  
    &__awards,
    &__testimonials {
      &__editor {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 1rem 0 0;
      }
  
      &__new {
        border-radius: 1rem;
        background-color: transparent;
        color: $white;
        border: 2px solid $white;
        font-size: .8125rem;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        padding: .3125rem 0;
        letter-spacing: 3px;
        cursor: pointer;
        transition: linear 100ms;
        margin-top: 1rem;
        outline: 0;
  
        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
  
  .award {
    @include award;
  }
  
  .testimonial {
    @include testimonial;
  }
}