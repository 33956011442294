@font-face {
  font-family: 'silomregular';
  src: url('/assets/fonts/silom-webfont.woff2') format('woff2'),
        url('/assets/fonts/silom-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'tricksterregular';
  src: url('/assets/fonts/trickster-reg-webfont.woff2') format('woff2'),
        url('/assets/fonts/trickster-reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'luminariregular';
  src: url('/assets/fonts/luminari.woff2') format('woff2'),
         url('/assets/fonts/luminari.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bespokefi0qlbxyq_';
  src: url('/assets/fonts/bespoke-webfont.woff2') format('woff2'),
        url('/assets/fonts/bespoke-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin LibreFranklin {
  font-family: 'Libre Franklin', sans-serif;
  font-style: normal;
}
@mixin NeueHaasDisplay {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
}
@mixin NeueHaasText {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-style: normal;
}
@mixin Amador {
  font-family: amador, Times, serif;
  font-style: normal;
  font-weight: 400;
}
@mixin Prohibition {
  font-family: prohibition, Impact, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin Silom {
  font-family: 'silomregular', Helvetica, sans-serif;
}
@mixin Trickster {
  font-family: 'tricksterregular', Times, serif;
}
@mixin Luminari {
  font-family: 'luminariregular', Times, serif;
}
@mixin Bespoke {
  font-family: 'bespokefi0qlbxyq_', Helvetica, sans-serif;
}