@mixin anim__glow {
	@keyframes glow {
		0%, 100% {
			opacity: 0.75;
		}
		50% {
			opacity: 0.25;
		}
	}
}
