@import '../../_styles/typo';

@mixin submit {
  height: 2.5rem;
  background-color: black;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 5;
  position: relative;

  .label {
    user-select: none;
    pointer-events: none;
    flex: none;
    width: 10rem;
    text-indent: -9999px;
    background-image: url('../_assets/enquire.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 2.5rem;
    transform: translateY(2px);
    display: inline-block;
  }
  .star {
    fill: white;
    width: 15px;
    height: 15px;
  }

  &, .star {
    transition: ease-in-out 150ms;
  }

  @media (hover:hover) {
    &:hover {
      background-color: white;

      .star {
        fill: black;
      }
    }
  }
  &:active,
  &:focus {
    background-color: white;

    .star {
      fill: black;
    }
  }

  &.mailto {
    margin-top: .5rem;
    height: 3rem;
  }
}

$primary: #9A742C;

.ads__contact {
  background-color: $primary;
  padding: 12px 8px;
  position: relative;
  -webkit-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  display: flex;
  flex-direction: column;
}

.contact {
  &__halftone {
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 70%;
    z-index: 0;
    pointer-events: none;

    img {
      object-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  &__prequote,
  &__stars,
  &__title,
  &__ohhai,
  &__form {
    position: relative;
    z-index: 5;
  }
  &__prequote,
  &__stars,
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    pointer-events: none;
  }
  &__prequote {
    color: black;
    font-family: rhode-condensed,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1rem;
    text-transform: uppercase;
    margin-bottom: .25rem;
  }
  &__stars {
    height: 15px;
    margin-bottom: .375rem;

    .star {
      width: 15px;
      height: 15px;
      fill: white;
    }
  }
  &__title {
    color: black;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1.8rem;
    margin-bottom: .325rem;
    font-family: itc-avant-garde-gothic-pro,sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  &__ohhai {
    position: relative;
    width: 100%;
    margin-bottom: 1.75rem;

    &, .media {
      height: 10rem;
    }
    .media {
      overflow: hidden;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    .yourwish {
      height: 92px;
      width: 170px;
      position: absolute;
      bottom: -24px;
      right: -6px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;

    input,
    textarea,
    button {
      width: 100%;
      border: 0;
      margin: 0;
      outline: 0;
      margin-bottom: .75rem;
      &:last-child { margin-bottom: 0; }
    }
    input,
    textarea {
      font-size: 1rem;
      color: black;
      padding: 8px 6px 4px;
      font-weight: 500;
      letter-spacing: 0.1px;
      background-color: rgba(154,116,44,.5);
      transition: ease-in-out 100ms;

      &::placeholder {
        color: inherit;
        opacity: .8;
      }

      &:focus,
      &:active {
        background-color: black;
        color: $primary;
      }
      @media (hover:hover) {
        &:hover {
          background-color: black;
          color: $primary;
        }
      }
    }
    textarea {
      resize: none;
      height: 4.5rem;
    }
    button {
      @include submit;
    }
  }
  &__submit {
    @include submit;
  }
}