@import '../_styles/button';
@import '../_styles/anim';

.page--projecteditor {
  form {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
  }

  &[data-loading="true"] {
    form {
      padding: 4rem;
      align-items: center;
      justify-content: center;
    }
  }
}
.projecteditor {
  &__main,
  &__secondary {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 48px 40px;

    &[data-saving="true"] {
      pointer-events: none;
    }
  }
  &__main {
    flex: 1;
  }
  &__secondary {
    width: 320px;
  }

  &__submit {
    @include ButtonPrimary;
    @include anim__glow;
    margin-bottom: .5rem;
  }
  &__cancel {
    @include ButtonPrimary;
    border: 0;
    margin-bottom: 2rem;
    color: red;
    background-color: $black;

    &:hover {
      color: $white;
      background-color: red;
    }
  }
  &__submit,
  &__cancel {
    &:disabled {
      pointer-events: none;
      opacity: .5;
    }
  }
  &__submit:disabled {
    animation: glow 1000ms infinite;
  }
}

.errors {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  &__error {
    width: 100%;
    background-color: red;
    color: $white;
    padding: .75rem;
    border-radius: .75rem;
    margin-bottom: .75rem;
    position: relative;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -0.1px;

    &:before {
      content: 'Error';
      margin-bottom: .25rem;
      text-transform: uppercase;
      color: $black;
      letter-spacing: 3px;
      font-size: .8125rem;
      font-weight: 400;
    }

    &__remove {
      width: 2.5rem;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      background-color: transparent;
      color: $white;
      font-size: 1.25rem;
      cursor: pointer;
      outline: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1rem;
        height: 1rem;
        fill: $white;
      }

      &:hover {
        svg {
          fill: $black;
        }
      }
    }
  }
}