@import '../_styles/colours';
@import '../_styles/anim';

.dashboard__projects {
  header {
    padding: 2rem 0 1rem;
    background-color: $black;
    position: sticky;
    top: 0;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
  }
  h2 {
    margin-right: 1.5rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  &__listhead,
  &__item {
    padding: .5rem 1px;
    display: flex;
  }
  &__listhead {
    position: sticky;
    top: 4.875rem;
    background-color: $black;
    border-bottom: 1px solid $white;
    margin-bottom: 1rem;
  }
  &__item {
    @include anim__glow;
    cursor: pointer;
    display: flex;
    align-items: center;

    &__col {
      margin-right: .75rem;
      &[data-col="title"] {
        flex: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;

        &:before {
          content: '>';
          display: block;
          width: 0px;
          overflow: hidden;
          flex: none;
          transition: 100ms linear;
        }
      }
      &[data-col="expertise"] {
        flex: none;
        width: 8rem;
      }
      &[data-col="completed"] {
        flex: none;
        width: 5rem;
        text-align: center;
      }
      &[data-col="delete"] {
        flex: none;
        width: 3rem;
        margin-right: 0;
        display: flex;
        justify-content: center;
      }
    }
    &>a {
      display: flex;
      text-decoration: none;
      color: inherit;
      flex: 1;
      width: 100%;
    }

    &:not(.dashboard__projects__listhead):hover {
      .dashboard__projects__item__col {
        &[data-col="title"] {
          &:before {
            width: 1rem;
          }
        }
      }
    }

    &[data-deleting="true"] {
      pointer-events: none;
      position: relative;
      animation: glow 1000ms infinite;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 100%;
        background-color: $white;
      }
    }
  }
  &__listhead {
    .dashboard__projects__item__col {
      cursor: pointer;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.button__newproject {
  width: 2rem;
  height: 2rem;
  position: relative;
  background-color: $white;
  border-radius: 1rem;
  text-decoration: none;
  overflow: hidden;

  &, .icon, .title {
    transition: 200ms ease-in-out;
  }
  .icon,
  .title {
    position: absolute;
    color: $black;
  }
  .icon {
    font-size: 2rem;
    z-index: 1;
    font-weight: 400;
    width: 2rem;
    line-height: 1.875rem;
    height: 2rem;
    top: 0;
    right: 0;
    text-align: center;
    transform: translate3d(0,0,0) rotateZ(0deg);
    transform-origin: center;
    flex: none;
  }
  .title {
    font-size: .875rem;
    line-height: 1.875rem;
    left: .75rem;
    top: .125rem;
    z-index: 0;
    opacity: 0;
    transform: translate3d(0,0,0);
    text-transform: uppercase;
    letter-spacing: 3px;
    flex: none;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    width: 11.375rem;

    .title {
      opacity: 1;
    }
    .icon {
      transform: translate3d(0,0,0) rotateZ(180deg);
    }
  }
}

.project__delete {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  flex: none;
  align-items: center;
  justify-content: center;

  svg {
    fill: red;
    width: 1rem;
    height: 1rem;
    flex: none;
  }

  &:hover {
    background-color: red;
    svg {
      fill: $white;
    }
  }
}