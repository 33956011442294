.cta {
  &__projects {

    &__list {
      @media (min-width: 1281px) {
        column-count: 2;
        column-gap: 1.5rem;
      }
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}