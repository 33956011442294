@import '../_styles/colours';

.landing__header {
  width: 100%;
  user-select: none;
  position: relative;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  h1 {
    font-size: 5.125rem;
    line-height: 4.25rem;
    letter-spacing: -0.125rem;
    font-weight: 500;
    margin-bottom: 1.125rem;

    b {
      font-weight: 700;
    }
  }
  h2 {
    font-size: 2.25rem;
    line-height: 2.1875rem;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 1.75rem;
  }
  br {
    display: none;
  }
}

.header__titles {
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  font-weight: 700;
  padding: .3125rem .625rem .25rem;
  background-color: $white;
  color: $black;
  display: grid;
  grid-auto-flow: column;
  grid-gap: .3125rem;
}

.App {
  &[data-layout="col-1"] {
    .landing__header {
      margin-top: 4rem;
      height: unset;
      margin-bottom: 8rem;
      display: flex;
      flex-direction: column;

      &__mark {
        width: 140px;
        height: 140px;
        margin-bottom: 4rem;
      }
      br {
        display: block;
      }
    }
  }
  &[data-layout="col-2"],
  &[data-layout="col-3"],
  &[data-layout="col-4"] {
    .landing__header {
      margin-top: 11rem;
      margin-bottom: 12.5rem;
      position: relative;
      height: 190px;

      &__mark {
        position: absolute;
        top: 50%;
        right: calc(100% + 48px);
        transform: translateY(-50%);
      }
    }
  }
  &[data-layout="col-2"],
  &[data-layout="col-3"] {
    .landing__header {
      &__mark {
        width: 180px;
        height: 180px;
      }
    }
  }
  &[data-layout="col-4"] {
    .landing__header {
      &__mark {
        width: 200px;
        height: 200px;
      }
    }
  }
}