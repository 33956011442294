@import '../_styles/colours';

.subsection__head {
  -webkit-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  font-size: 1.125rem;
  line-height: 1em;
  letter-spacing: 0;
  font-weight: 600;
  color: $white;
  padding: .1875rem 2px .3125rem;
  border-bottom: 1px solid $white;
  margin-bottom: 1rem;
}